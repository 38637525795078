import React from 'react';
import { ThemeProvider } from 'styled-components';

import ConfirmForm from './ConfirmForm';
import PrivacyButton from './PrivacyButton';
import ProvinceSelect from './ProvinceSelect';
import theme from '../theme';


/**
 * Render main confirmation form that presents user with country/province select and 3 buttons
 */
class PrivacyForm extends React.Component {
  constructor(props) {
    super(props);

    const { csrfValue, countries, provinces, privacyDates } =  window.__PRIVACY_APP_STATE__;
    const confirm = false;
    const selectedCountry = '';
    const selectedProvince = '';

    this.state = {
      countries,
      provinces,
      selectedCountry,
      selectedProvince,
      privacyDates,
      confirm,
      csrfValue
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
  }

  /**
   * Triggers on country field change. Also resets default country and province accordingly
   *
   * @param {ChangeEvent} event onchange event
   * @return {void}
   */
  handleCountryChange(event) {
    const selectedCountry = event.target.value;

    this.setState({selectedCountry: selectedCountry, selectedProvince: ''});
  }

  /**
   * Triggers on province/state field change. Also resets default province
   *
   * @param {ChangeEvent} event onchange event
   * @return {void}
   */
  handleProvinceChange(event) {
    this.setState({selectedProvince: event.target.value});
  }

  /**
   * Toggle 'confirm' value (<ConfirmForm>), and reset the default country and province
   *
   * @param {ClickEvent} event event
   * @param {string|bool} value false || action string (get-data, share-data, delete-data)
   * @returns {void}
   */
  toggleConfirm(event, value) {
    event.preventDefault();

    if (value === false) {
      this.setState({confirm: value, selectedCountry: '', selectedProvince: ''});
    } else {
      this.setState({confirm: value});
    }
  }

  /**
   * Logic for whether individual buttons should be disabled or not.
   * note: get-data can be requested twice a year
   *
   * @param {string|null} date YYYY-MM-DD
   * @param {string} action get-data, share-data, delete-data
   * @return {bool} whether button is disabled or not (true = disabled, false = clickable/enabled)
   */
  getBtnDisabledState(date, action) {
    // get-data allows 2 requests per year
    const parseDate = moment(date);

    if (parseDate.isValid()) {
      if (action === 'get-data' && parseDate < moment().subtract(6, 'months')) {
        return false;
      }

      return true;
    }

    return false;
  }

  /**
   * Figure out if all buttons on form should be disabled or not
   * US requires province, CA and UK do not
   *
   * @param {string} selectedCountry country
   * @param {string} selectedProvince state/province
   * @return {bool} whether all buttons should be disabled or not
   */
  getAllButtonsDisabled(selectedCountry, selectedProvince) {
    if (selectedCountry === 'US') {
      if (selectedProvince)  {
        return false;
      }
    } else if (selectedCountry.length >= 1) {
      return false;
    }

    return true;
  }

  /**
   * Render the privacy form
   *
   * @returns {Object} privacy form
   */
  render() {
    const {
      countries,
      provinces,
      selectedCountry,
      selectedProvince,
      privacyDates,
      confirm,
      csrfValue
    } = this.state;

    const toggleConfirm = (e, action) => this.toggleConfirm(e, action);
    const allButtonsDisabled = this.getAllButtonsDisabled(selectedCountry, selectedProvince);

    if (confirm) {
      return (
        <ThemeProvider theme={theme}>
          <div id="privacy-confirm">
            <ConfirmForm
              action={confirm}
              selectedCountry={selectedCountry}
              selectedProvince={selectedProvince}
              csrfValue={csrfValue}
              parentMethod={this.toggleConfirm}
            />
          </div>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <div>
          <h1 className="privacy-heading1">Privacy Tools</h1>
          <p className="privacy-header-p">Please use this form if you would like to make a request for yourself and your own information.  If you would like to submit a request as an authorized agent on behalf of someone else, please email privacy@soul-cycle.com with the subject “CCPA Rights Request”.</p>

          <form id="privacy-form" action="#">

            <div className="privacy-region">
              <div className="privacy-select">
                <label htmlFor="country">Place of residence</label>
                <select name="country" id="privacy--country" required onChange={(e) => this.handleCountryChange(e)}>
                  <option value="false" defaultValue />
                  {Object.keys(countries).map((country) => <option key={countries[country].iso_code} value={countries[country].iso_code}>{countries[country].name}</option> )}
                </select>
              </div>
              <div id="province-container" className="privacy-select">
                <label htmlFor="state">State</label>
                <ProvinceSelect
                  provinces={provinces}
                  selectedCountry={selectedCountry}
                  parentMethod={this.handleProvinceChange}
                />
              </div>
            </div>

            <div id="sharing-your-data" className="privacy-btn-container">
              <div className="privacy-btn-header">
                <h4>Do not sell my information</h4>
                <p>Opt-out of the sale of your personal information. Please note that this may affect your interaction with certain SoulCycle experiences.</p>
              </div>
              <div className="privacy-content">
                <PrivacyButton
                  handleButtonClick={toggleConfirm}
                  btnDisabled={this.getBtnDisabledState(privacyDates.data_do_not_sell_requested_at, 'share-data')}
                  allButtonsDisabled={allButtonsDisabled}
                  action="share-data">
                  {privacyDates.data_do_not_sell_requested_at ? 'Your information will not be shared with third parties' : 'Stop sharing information with third parties'}
                </PrivacyButton>
              </div>
            </div>

            <div id="getting-your-data" className="privacy-btn-container">
              <div className="privacy-btn-header">
                <h4>Data access request</h4>
                <p>Request a copy of your personal information. You have the right to make up to 2 data access requests a year.</p>
              </div>
              <div className="privacy-content">
                <PrivacyButton
                  handleButtonClick={toggleConfirm}
                  btnDisabled={this.getBtnDisabledState(privacyDates.data_retrieval_requested_at, 'get-data')}
                  allButtonsDisabled={allButtonsDisabled}
                  action="get-data">
                  {privacyDates.data_retrieval_requested_at ?
                    'You requested a copy of your personal information on ' + moment(privacyDates.data_retrieval_requested_at, 'YYYY-MM-DD HH:mm:ss').format('MM/D/YYYY')
                    :
                    'Request a copy of your personal information'}
                </PrivacyButton>
              </div>
            </div>

            <div id="deleting-your-data" className="privacy-btn-container">
              <div className="privacy-btn-header">
                <h4>Data deletion request</h4>
                <p>
                  Request that all your personal information that SoulCycle has collected be deleted, subject to certain exceptions permitted or required by law. Please be aware that this will result in your SoulCycle account, rider history, purchase history, reservations, and any unredeemed series credits being permanently deleted. You will need to create a new account if you want to ride again in the future.
                </p>
              </div>
              <div className="privacy-content">
                <PrivacyButton
                  handleButtonClick={toggleConfirm}
                  btnDisabled={this.getBtnDisabledState(privacyDates.data_deletion_requested_at, 'delete-data')}
                  allButtonsDisabled={allButtonsDisabled}
                  action="delete-data">
                  {privacyDates.data_deletion_requested_at ? 'Your account and data will be deleted soon' : 'Permanently delete all personal information'}
                </PrivacyButton>
              </div>
            </div>

          </form>
        </div>
      </ThemeProvider>
    );
  }
}

export default PrivacyForm;
