import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Header } from 'soulcycle-ui-kit';
import theme from './theme';
import { headerDefault, headerUK, userBadgeCampaign } from './fixtures/header';
import { findCountry, getMatchingRegion, mapStudiosAndRegions } from './regions';
import analytics from './utils/podium-analytics';
import { callBeforeNavigation } from './utils/navigation';
import {cloneDeep} from 'lodash';

/**
 * Click handler for the region toggle button
 *
 * @param {boolean} isOpen region selector menu state
 *
 * @returns {undefined}
 */
function handleRegionToggle(isOpen) {
  if (isOpen) {
    analytics.header.trackRegionSelectorOpen();
  }
}

/**
 * Click handler for the navigation items
 *
 * @param {object} event event object
 * @param {string} itemName name of the navigation item
 *
 * @returns {undefined}
 */
function handleNavItemClick(event, itemName) {
  const href = event.target.getAttribute('href');
  const tracking = () => {
    analytics.header.trackNavItemClick(itemName);
  };

  callBeforeNavigation(tracking, href, event);
}

/**
 * Click handler for the logo
 *
 * @param {object} event event object
 *
 * @returns {undefined}
 */
function handleLogoClick(event) {
  const tracking = () => {
    analytics.header.trackNavItemClick('Logo');
  };

  callBeforeNavigation(tracking, '/', event);
}

/**
 * Change handler for header studio list
 *
 * @param {object} studio selected studio object
 *
 * @returns {undefined}
 */
function handleStudioSelect(studio) {
  const tracking = () => {
    analytics.header.trackStudioSelect(studio.name);
  };

  callBeforeNavigation(tracking, `/find-a-class/studio/${studio.id}`);
}

/**
 * Get the fixture data corresponding to the country code
 *
 * @param {Object} appState application hydration state
 * @returns {Object} header fixture
 */
function getCountryHeaderFixture(appState) {
  const { COUNTRY_CODE, COUNTRY_CODE_UK } = appState;
  if (COUNTRY_CODE === COUNTRY_CODE_UK) {
    return headerUK;
  }

  return headerDefault;
}

/**
 * push a SoulCup menu item on the user badge menu
 *
 * @param {Object} userBadgeList user menu items
 *
 * @returns {Object} userBadgeList user menu items with the soulcupOption
 */
function pushSoulCupItem(userBadgeList) {
  userBadgeList.splice(1, 0, userBadgeCampaign);
  return userBadgeList;
}

class AppHeader extends React.Component {
  constructor(props) {
    super(props);

    const appState = window.__APP_STATE__;

    const { navItems, userBadgeList } = getCountryHeaderFixture(appState);
    const user = appState.rider && appState.rider.id ? {
      firstName: appState.rider.first_name,
      lastName: appState.rider.last_name,
      id: parseInt(appState.rider.id, 10)
    } : null;

    const userBadgeProps = {
      user: null,
      urlList: appState.IS_SOULCUP_ENROLLABLE ? pushSoulCupItem(cloneDeep(userBadgeList)) : userBadgeList
    };

    if (user) {
      userBadgeProps.user = user;
    }

    const riderSeries = appState.rider_series;
    const usableCredits = riderSeries && riderSeries.usable_credits ? riderSeries.usable_credits : 0;
    const seriesCount = riderSeries && riderSeries.has_unlimited ? ' ∞ + ' + usableCredits : usableCredits;
    const regions = mapStudiosAndRegions(appState.studios_by_country_and_region);
    const showSeriesCount = true;

    const initialRegionId = window.__APP_STATE__.rider
      ? (parseInt(window.__APP_STATE__.rider.region, 10) || 1)
      : 1;

    const currentCountry = findCountry(regions, initialRegionId);
    const currentRegion = getMatchingRegion(regions, initialRegionId);

    this.state = {
      currentCountry,
      currentRegion,
      navItems,
      regions,
      seriesCount,
      showSeriesCount,
      userBadgeProps
    };

    this.onHeaderRegionChange = this.onHeaderRegionChange.bind(this);
  }

  /*
   * Handle region change.
   *
   * @params {Object} selectedRegion region that was selected
   * @returns {undefined} undefined
   */
  onHeaderRegionChange(selectedRegion) {
    const { regions } = this.state;

    analytics.header.trackRegionSelect(selectedRegion.name);
    // NOTE: This is tentatively a place to add other behavior when there is a
    // shop-counter in the Header. For example, the legacy behavior warned about
    // losing items in the cart if the region was changed to match another
    // country.
    const selectedCountry = findCountry(regions, selectedRegion.id);
    const isSameCountry = selectedCountry === this.state.currentCountry;

    SC.utils.changeRegion(selectedRegion.id, !isSameCountry, SC.utils.updateBubbleIndicatorClass);
    const currentRegion = getMatchingRegion(regions, selectedRegion.id);

    this.setState({ currentCountry: selectedCountry, currentRegion });
  }

  /**
   * Render the header
   *
   * @returns {Object} Header
   */
  render() {
    const {
      currentRegion,
      navItems,
      regions,
      seriesCount,
      showSeriesCount,
      userBadgeProps
    } = this.state;
    const currentPath = window.location.pathname;

    return (
      <ThemeProvider theme={theme}>
        <Header
          currentPath={currentPath}
          currentRegion={currentRegion}
          defaultRegionId={currentRegion.id}
          navItems={navItems}
          onLogoClick={handleLogoClick}
          onNavItemClick={handleNavItemClick}
          onRegionChange={this.onHeaderRegionChange}
          onRegionToggle={handleRegionToggle}
          onStudioSelect={handleStudioSelect}
          onSubNavItemClick={handleNavItemClick}
          regions={regions}
          seriesCount={seriesCount}
          showSeriesCount={showSeriesCount}
          studios={currentRegion.studios}
          userBadgeProps={userBadgeProps}
        />
      </ThemeProvider>
    );
  }
}

let appHeader;

/**
 * Re-render the Header
 *
 * @param {Object} state to override in component
 * @returns {undefined} undefined
 */
function rerender(state) {
  appHeader.setState(state);
}

/**
 * Mount the Header
 * NOTE: This is meant to be executed only once when application starts
 *
 * @param {Object} props to override in component
 * @returns {undefined} undefined
 */
function mount() {
  appHeader = ReactDOM.hydrate(
    <AppHeader />,
    document.querySelector('.header-container')
  );
}

export default { mount, rerender };
