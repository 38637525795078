import { track, types } from 'podium-analytics-js-sdk';

/**
 * Checks if MParticleActive is ready.
 *
 * @returns {boolean} true if MParticle is active | false otherwise.
 */
function isMparticleActive() {
  return !!(
    typeof window !== 'undefined' &&
      window.mParticle &&
      window.mParticle.logEvent &&
      window.mParticle.EventType
  );
}

/**
 * Adds an mParticle:identity-success event listener that tracks user attributes
 *
 * @returns {undefined}
 */
function trackUserAttributes() {
  /* istanbul ignore else  */
  if (typeof document !== 'undefined') {
    document.addEventListener('mParticle:identity-success', () => {
      let search = (window && window.location && window.location.search) || '';
      search = search.replace(/^\?/, '');

      if (search) {
        const attributes = search.split('&').reduce((acc, pair) => {
          const [key, value] = pair.split('=');
          return {
            ...acc,
            [key]: value
          };
        }, {});

        track({ type: types.NAVIGATION, name: 'Identity success' });
        track({ type: types.USER_ATTRIBUTES, attributes });
      }
    });
  }
}

/**
 * Tracks navigation item click
 *
 * @param {string} itemName - name of the navigation item
 *
 * @returns {undefined}
 */
function trackNavItemClick(itemName) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Navigation Link',
    attributes: {
      Location: 'Navigation',
      // TODO: Get context passed as an argument instead of hardcoding the breakpoint here
      Context: window && window.innerWidth < 1024 ? 'Mobile' : 'Desktop',
      'Link Name': itemName
    }
  });
}

/**
 * Tracks region select
 *
 * @param {string} regionName - name of the selected region
 *
 * @returns {undefined}
 */
function trackRegionSelect(regionName) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click New Region',
    attributes: {
      Location: 'Navigation',
      'Region Name': regionName
    }
  });
}

/**
 * Tracks region selector open state
 *
 * @returns {undefined}
 */
function trackRegionSelectorOpen() {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Browsing Regions',
    attributes: {
      Location: 'Navigation'
    }
  });
}

/**
 * Tracks studio select
 *
 * @param {string} studioName - name of the selected studio
 *
 * @returns {undefined}
 */
function trackStudioSelect(studioName) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Studio Page',
    attributes: {
      Location: 'Navigation',
      Context: 'Studio List',
      'Studio Name': studioName
    }
  });
}

/**
 * Tracks carousel button click
 *
 * @param {string} imageTitle - carousel slide image title
 * @param {string} url - url of the carousel button
 * @param {number} slideNumber - number of the carousel slide
 *
 * @returns {undefined}
 */
function trackCarouselButtonClick(imageTitle, url, slideNumber) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Elevator Carousel',
    attributes: {
      Location: 'Homepage',
      'Image Title': imageTitle,
      URL: url,
      Slide: slideNumber
    }
  });
}

/**
 * Tracks short card click
 *
 * @returns {undefined}
 *
 * @param {string} imageTitle - short card image title
 * @param {string} url - url of short card
 * @param {number} cardNumber - number of the short card
 */
function trackShortCardClick(imageTitle, url, cardNumber) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Marketing Module',
    attributes: {
      Location: 'Homepage',
      'Image Title': imageTitle,
      URL: url,
      Number: cardNumber
    }
  });
}

/**
 * Tracks hero button click
 *
 * @returns {undefined}
 */
function trackHeroButtonClick() {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Book Now',
    attributes: {
      Location: 'Hero Section'
    }
  });
}

/**
 * Tracks polaroid card click
 *
 * @returns {undefined}
 *
 * @param {string} imageTitle - polaroid card image title
 * @param {string} url - url of polaroid card
 * @param {number} cardNumber - number of the polaroid card
 */
function trackPolaroidCardClick(imageTitle, url, cardNumber) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click News Module',
    attributes: {
      Location: 'Homepage',
      'Image Title': imageTitle,
      URL: url,
      Number: cardNumber
    }
  });
}

/**
 * Tracks banner click
 *
 * @returns {undefined}
 *
 * @param {string} imageTitle - banner image title
 * @param {string} url - url of the banner button
 */
function trackBannerClick(imageTitle, url) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Banner',
    attributes: {
      Location: 'Homepage',
      'Image Title': imageTitle,
      URL: url
    }
  });
}

/**
 * Tracks long card click
 *
 * @returns {undefined}
 *
 * @param {string} imageTitle - long card image title
 * @param {string} url - url of the long card
 */
function trackLongCardClick(imageTitle, url) {
  if (!isMparticleActive()) return;

  track({
    type: types.NAVIGATION,
    name: 'Click Elevator Module',
    attributes: {
      Location: 'Homepage',
      'Image Title': imageTitle,
      URL: url
    }
  });
}

const header = {
  trackNavItemClick,
  trackRegionSelect,
  trackRegionSelectorOpen,
  trackStudioSelect
};

const homepage = {
  trackCarouselButtonClick,
  trackShortCardClick,
  trackHeroButtonClick,
  trackPolaroidCardClick,
  trackBannerClick,
  trackLongCardClick
};

export default { header, homepage, trackUserAttributes };
