/**
 * Navigates to URL checking first if the address
 * is internal or external.
 *
 * @param {string} url URL to navigate to.
 * TODO: Use a more robust method to determine if a link is internal or external
 *
 * @returns {undefined}
 */
export function navigateToURL(url) {
  if (typeof url === 'string') {
    // determine if a link is internal by checking the first character
    if (url.indexOf('/') === 0 || url.indexOf('#') === 0) {
      // link is internal, open in the same window
      window.location = url;
    } else {
      // link is external, open in new window
      window.open(url);
    }
  } else {
     
    console.error('Attempted navigating to invalid URL', url);
  }
}

/**
 * Calls a function after preventing the default behavior of an event
 * and before navigating to a given URL.
 *
 * @param {Function} callback - the function to call before navigation
 * @param {string} url - the url to navigate to after the callback
 * @param {Object} [event] - an event to call preventDefault on
 *
 * @returns {undefined}
 */
export function callBeforeNavigation(callback, url, event) {
  if (event && typeof event.preventDefault === 'function') {
    event.preventDefault();
  }

  callback();
  navigateToURL(url);
}

export default { callBeforeNavigation, navigateToURL };
