import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'soulcycle-ui-kit';
import axios from 'axios';

const titles = {
  'share-data': {
    title: 'Do not sell my information',
    text: <p><b>Please Confirm</b> that you would like to opt out of the sale of your personal information by SoulCycle to third parties. Note that this opt-out may affect your interaction with certain SoulCycle experiences.</p>
  },
  'get-data': {
    title: 'Data access request',
    text: <p><b>Please confirm</b> that you would like to receive a copy of your personal information.</p>
  },
  'delete-data': {
    title: 'Data deletion request',
    text: <p><b>Please confirm</b> that you would like to delete your personal information that SoulCycle has collected. By clicking this button you acknowledge you understand your SoulCycle account, rider history, purchase history, reservations, and any unredeemed series credits being permanently deleted. You will need to create a new account if you want to ride again in the future.</p>
  }
};

const NotApprovedMessage = <p>Hi There! Looks like we are unable to complete your request. Only California residents are entitled to rights under CCPA.</p>;

const isWithinJurisdiction = (country, province) => country === 'US' && province === 'California';

/**
 * Render the confirmation form for when a user clicks an action on the main form
 *
 * @param {object} props passed down
 * @return {string} html
 */
const ConfirmForm = (props) => {
  const [submitStatus, setSubmitStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { parentMethod, selectedCountry, selectedProvince, action, csrfValue } = props;
  const clickParentMethod = (e, value) => parentMethod(e, value);

  const isValidRequest = isWithinJurisdiction(selectedCountry, selectedProvince);
  const message = isValidRequest ? titles[action].text : NotApprovedMessage;

  const mySubmitHandler = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append('country', e.target.country.value);
    form.append('state', e.target.state.value);
    form.append('_action', e.target._action.value);
    form.append('csrf_token', e.target.csrf_token.value);
    setSubmitStatus(true);

    axios.post('/profile/privacy/submit/', form)
      .then(async (response) => {
        const data = response.data;
        if (data.data_do_not_sell_approved_at === true && data.db_status === true) {
          await SC.utils.setCookie('usprivacy', '1-Y-');
          await SC.analytics.mp.setupConsent(Date.now(), '', 'web_manual_opt_out');
        }

        window.location.href = '/profile/privacy';
      })
      .catch((error) => {
        setSubmitStatus(false);
        setErrorMessage(error.response.data.error);
      });
  };

  return (
    <div id={`action-${action}`} className="action-container">
      <h1 className="privacy-heading1">{titles[action].title}</h1>

      <form onSubmit={mySubmitHandler}>
        {message}

        <input type="hidden" name="country" value={selectedCountry} />
        <input type="hidden" name="state" value={selectedProvince} />
        <input type="hidden" name="_action" value={action} />
        <input type="hidden" name="csrf_token" id="frm-csrf_token" value={csrfValue} />

        <div className="privacy-confirm-btn">
          {isValidRequest && <React.Fragment>
            <Button id="cancel-req-btn" text="Cancel Request" variation="secondary" onClick={(e) => clickParentMethod(e, false)} />
            <Button id="submit-req-btn" text="Submit" type="submit" disabled={submitStatus} />
          </React.Fragment>}

          {!isValidRequest && <Button id="back-req-btn" text="I Understand" onClick={(e) => clickParentMethod(e, false)} />}
        </div>
      </form>

      <div className="error-msg">
        {errorMessage}
      </div>
    </div>
  );
};

ConfirmForm.propTypes = {
  action: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  selectedProvince: PropTypes.string.isRequired,
  csrfValue: PropTypes.string.isRequired,
  parentMethod: PropTypes.func
};

export default ConfirmForm;
