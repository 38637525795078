const footerDefault = Object.freeze({
  items: Object.freeze({
    company: [
      {
        id: 'our-story',
        name: 'Our story',
        url: '/our-story/'
      },
      {
        id: 'careers',
        name: 'Careers',
        url: '/careers/'
      },
      {
        id: 'philanthropy',
        name: 'Philanthropy',
        url: '/philanthropy/'
      }
    ],
    help: [
      {
        id: 'faq',
        name: 'FAQ',
        url: '/faq/'
      },
      {
        id: 'terms',
        name: 'Terms & Conditions',
        url: '/legal/terms-and-conditions/'
      }, {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        url: '/legal/privacy-policy/'
      },
      {
        id: 'rider-code-conduct',
        name: 'Rider Code of Conduct',
        url: '/soulconnected/rider-code-of-conduct/'
      },
      {
        id: 'california-privacy',
        name: 'California Privacy',
        url: '/legal/privacy-policy#shine-the-light'
      },
      {
        id: 'california-notice-of-collection',
        name: 'California Notice of Collection',
        url: '/legal/privacy-policy#notice-of-collection'
      },
      {
        id: 'right-to-opt-out',
        name: 'Do Not Sell My Personal Information',
        url: '/legal/privacy-policy#right-to-opt-out'
      },
      {
        id: 'childrens-privacy',
        name: 'Children’s Privacy',
        url: '/legal/childrens-privacy/'
      },
      {
        id: 'contact-us',
        name: 'Contact Us',
        url: 'mailto:yoursoulmatters@soul-cycle.com'
      }
    ],
    'account / shop': [
      {
        id: 'sign-in',
        name: 'Sign In',
        url: '/signin/'
      },
      {
        id: 'gift-card-purchase',
        name: 'Purchase Gift Card',
        url: 'https://www.buyatab.com/custom/soulcycle/?page=landing',
        target: '_blank'
      },
      {
        id: 'gift-card',
        name: 'Gift Card Balance',
        url: '{{ \'https://\' ~ SHOP_SITE_URL ~ \'/ca/en/gift-card-balance\' }}',
        target: '_blank'
      },
      {
        id: 'purchase-history',
        name: 'Purchase History',
        url: '/profile/history/purchases/'
      },
      {
        id: 'returns',
        name: 'Returns',
        url: '/faq#returns'
      }
    ]
  }),
  socials: ['instagram', 'facebook', 'twitter'],
  fullSocials: ['instagram', 'twitter', 'email', 'facebook']
});

const footerDefaultHistory = Object.freeze({
  items: Object.freeze({
    company: [
      {
        id: 'our-story',
        name: 'Our story',
        url: '/our-story/'
      },
      {
        id: 'careers',
        name: 'Careers',
        url: '/careers/'
      },
      {
        id: 'philanthropy',
        name: 'Philanthropy',
        url: '/philanthropy/'
      }
    ],
    help: [
      {
        id: 'faq',
        name: 'FAQ',
        url: '/faq/'
      },
      {
        id: 'terms',
        name: 'Terms & Conditions',
        url: '/legal/terms-and-conditions/'
      },
      {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        url: '/legal/privacy-policy/'
      },
      {
        id: 'rider-code-conduct',
        name: 'Rider Code of Conduct',
        url: '/soulconnected/rider-code-of-conduct/'
      },
      {
        id: 'california-privacy',
        name: 'California Privacy',
        url: '/legal/privacy-policy#shine-the-light'
      },
      {
        id: 'california-notice-of-collection',
        name: 'California Notice of Collection',
        url: '/legal/privacy-policy#notice-of-collection'
      },
      {
        id: 'right-to-opt-out',
        name: 'Do Not Sell My Personal Information',
        url: '/legal/privacy-policy#right-to-opt-out'
      },
      {
        id: 'childrens-privacy',
        name: 'Children’s Privacy',
        url: '/legal/childrens-privacy/'
      },
      {
        id: 'contact-us',
        name: 'Contact Us',
        url: 'mailto:yoursoulmatters@soul-cycle.com'
      }
    ],
    'account / shop': [
      {
        id: 'sign-in',
        name: 'Sign In',
        url: '/signin/'
      },
      {
        id: 'gift-card-purchase',
        name: 'Purchase Gift Card',
        url: 'https://www.buyatab.com/custom/soulcycle/?page=landing',
        target: '_blank'
      },
      {
        id: 'gift-card',
        name: 'Gift Card Balance',
        url: '{{ \'https://\' ~ SHOP_SITE_URL ~ \'/us/en/gift-card-balance\' }}',
        target: '_blank'
      },
      {
        id: 'purchase-history',
        name: 'Series Purchases',
        url: '/profile/history/'
      },
      {
        id: 'returns',
        name: 'Returns',
        url: '/faq#returns'
      }
    ]
  }),
  socials: ['instagram', 'facebook', 'twitter'],
  fullSocials: ['instagram', 'twitter', 'email', 'facebook']
});

const footerUK = Object.freeze({
  items: Object.freeze({
    company: [
      {
        id: 'our-story',
        name: 'Our story',
        url: '/our-story/'
      },
      {
        id: 'careers',
        name: 'Careers',
        url: '/careers/'
      },
      {
        id: 'philanthropy',
        name: 'Philanthropy',
        url: '/philanthropy/'
      }
    ],
    help: [
      {
        id: 'faq',
        name: 'FAQ',
        url: '/uk/faq/'
      },
      {
        id: 'terms',
        name: 'Terms & Conditions',
        url: '/uk/legal/terms-and-conditions/'
      },
      {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        url: '/uk/legal/privacy-policy/'
      },
      {
        id: 'rider-code-conduct',
        name: 'Rider Code of Conduct',
        url: '/soulconnected/rider-code-of-conduct/'
      },
      {
        id: 'contact-us',
        name: 'Contact Us',
        url: 'mailto:yoursoulmatters@soul-cycle.com'
      }
    ],
    'account / shop': [
      {
        id: 'sign-in',
        name: 'Sign In',
        url: '/signin/'
      },
      {
        id: 'purchase-history',
        name: 'Purchase History',
        url: '/profile/history/purchases/'
      },
      {
        id: 'returns',
        name: 'Returns',
        url: '/faq#returns'
      }
    ]
  }),
  socials: ['instagram', 'facebook', 'twitter'],
  fullSocials: ['instagram', 'twitter', 'email', 'facebook']
});

const footerUKHistory = Object.freeze({
  items: Object.freeze({
    company: [
      {
        id: 'our-story',
        name: 'Our story',
        url: '/our-story/'
      },
      {
        id: 'careers',
        name: 'Careers',
        url: '/careers/'
      },
      {
        id: 'philanthropy',
        name: 'Philanthropy',
        url: '/philanthropy/'
      }
    ],
    help: [
      {
        id: 'faq',
        name: 'FAQ',
        url: '/uk/faq/'
      },
      {
        id: 'terms',
        name: 'Terms & Conditions',
        url: '/uk/legal/terms-and-conditions/'
      },
      {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        url: '/uk/legal/privacy-policy/'
      },
      {
        id: 'rider-code-conduct',
        name: 'Rider Code of Conduct',
        url: '/soulconnected/rider-code-of-conduct/'
      },
      {
        id: 'contact-us',
        name: 'Contact Us',
        url: 'mailto:yoursoulmatters@soul-cycle.com'
      }
    ],
    'account / shop': [
      {
        id: 'sign-in',
        name: 'Sign In',
        url: '/signin/'
      },
      {
        id: 'purchase-history',
        name: 'Series Purchases',
        url: '/profile/history/'
      },
      {
        id: 'returns',
        name: 'Returns',
        url: '/faq#returns'
      }
    ]
  }),
  socials: ['instagram', 'facebook', 'twitter'],
  fullSocials: ['instagram', 'twitter', 'email', 'facebook']
});

export { footerDefault, footerUK, footerDefaultHistory, footerUKHistory };
