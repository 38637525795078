import PropTypes from 'prop-types';
import React from 'react';


/**
 * Construct <select> tag that contains special logic for Canada and UK
 * We only require province field for United States. Add a blank <option> if not US
 *
 * @param {object} props passed down
 * @return {string} html
 */
const ProvinceSelect = (props) => {
  const onChangeParentMethod = (e) => props.parentMethod(e);
  const blankOption = <option key="blank" value="false" defaultValue />;

  if (props.selectedCountry) {
    return (
      <select name="state" id="privacy--state" required onChange={(e) => onChangeParentMethod(e)}>
        {props.provinces[props.selectedCountry].map((province, index) => {
          if (index === 0) {
            return [blankOption, <option key={province.name} value={province.name}>{province.name}</option>];
          }

          return <option key={province.name} value={province.name}>{province.name}</option>;
        })}
      </select>
    );
  }

  return (
    <select name="state" id="privacy--state" required />
  );
};

ProvinceSelect.propTypes = {
  provinces: PropTypes.object.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  parentMethod: PropTypes.func
};

export default ProvinceSelect;
