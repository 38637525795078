import { cloneDeep } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import { ThemeProvider } from 'styled-components';
import { theme, Footer } from 'soulcycle-ui-kit';
import { footerDefault, footerUK, footerDefaultHistory, footerUKHistory } from './fixtures/footer';
import { track, types } from 'podium-analytics-js-sdk';


/**
 * Get the fixture data corresponding to the country code
 *
 * @param {boolean} isUK asking if is a UK region
 * @param {boolean} isCA asking if is a CA region
 * @returns {Object} header fixture
 */
function getHistoryHeaderFixture(isUK, isCA) {
  if (isUK) {
    return footerUKHistory.items;
  }

  const footerItems = cloneDeep(footerDefaultHistory.items);
  if (isCA) {
    const filteredFooterHelp = footerItems.help.filter(
      link =>
        [
          'additional-disclosures-for-california-residents',
          'childrens-privacy',
          'right-to-opt-out'
        ].indexOf(
          link.id
        ) === -1
    );
    footerItems.help = filteredFooterHelp;
  }

  return footerItems;
}

/**
 * Get the footer items corresponding to the country code
 *
 * @param {Object} appState application hydration state
 * @returns {Object} country footer items
 */
function getCountryFooterItems(appState) {
  const { COUNTRY_CODE, COUNTRY_CODE_CA, COUNTRY_CODE_UK, PURCHASE_HISTORY_SPLIT_ACTIVE } = appState;

  if (PURCHASE_HISTORY_SPLIT_ACTIVE) {
    return getHistoryHeaderFixture(COUNTRY_CODE === COUNTRY_CODE_UK, COUNTRY_CODE === COUNTRY_CODE_CA);
  }

  if (COUNTRY_CODE === COUNTRY_CODE_UK) {
    return footerUK.items;
  }

  let footerItems = cloneDeep(footerDefault.items);
  if (COUNTRY_CODE === COUNTRY_CODE_CA) {
    const filteredFooterHelp = footerItems.help.filter(
      link =>
        [
          'additional-disclosures-for-california-residents',
          'childrens-privacy',
          'right-to-opt-out'
        ].indexOf(
          link.id
        ) === -1
    );
    footerItems.help = filteredFooterHelp;
  }

  return footerItems;
}

class AppFooter extends React.Component {
  constructor(props) {
    super(props);

    const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)SC_EMAIL_CAPTURE_STATE\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const appState = window.__APP_STATE__;
    const footerItems = getCountryFooterItems(appState);
    const emailValue = '';
    const showMailingBox = (appState.rider && appState.rider.id || cookieValue === 'removed') ? false : true;
    const errorMessage = '';
    const successMessage = '';

    this.state = {
      footerItems,
      emailValue,
      showMailingBox,
      errorMessage,
      successMessage
    };

    this.onChangeHandlerEmail = this.onChangeHandlerEmail.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onChangeHandlerEmail(event) {
    const emailInput = event.target.value;

    this.setState({emailValue: emailInput});
  }

  onSubmitHandler() {
    if (SC.utils.validation.isEmail(this.state.emailValue)) {
      this.setState({errorMessage: '', successMessage: ''});
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: querystring.stringify({
          email: this.state.emailValue,
          location: 'Footer'
        })
      };

      fetch('/email-capture/', requestOptions)
        .then(async response => {
          if (response.ok) {
            // const data = await response.json();

            // set cookie expiration as far out as possible
            document.cookie = 'SC_EMAIL_CAPTURE_STATE=removed; expires=Tue, 19 Jan 2038 00:00:00 GMT; path=/;';
            this.setState({successMessage: 'Successfully Submitted!', errorMessage: ''});

            track({
              type: types.USER_ATTRIBUTES,
              attributes: {
                email: this.state.emailValue,
                lp_name: 'webemailsignup',
                'email-captured': 1
              }
            });

            // hide email capture modal if it exists
            const emailCapture = document.getElementById('email-capture');
            if (emailCapture) {
              emailCapture.style.display = 'none';
            }
          } else {
            // .fetch doesn't seem to catch 400 errors... so we're manually 'catching'
            const error = await response.json();
            if (error.error) {
              this.setState({ errorMessage: error.error, successMessage: '' });
            } else {
              this.setState({ errorMessage: 'Error Processing Email', successMessage: '' });
            }
          }
        })
        .catch(() => {
          this.setState({ errorMessage: 'Error Processing Email', successMessage: '' });
        });
    } else {
      this.setState({errorMessage: 'Error: Invalid email address', successMessage: ''});
    }
  }

  render() {
    const {
      footerItems,
      emailValue,
      errorMessage,
      showMailingBox,
      successMessage
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Footer
          emailErrorMessage={errorMessage}
          emailSuccessMessage={successMessage}
          emailValue={emailValue}
          items={this.props.items || footerItems}
          onChangeHandlerEmail={this.onChangeHandlerEmail}
          onSubscriptionClick={this.onSubmitHandler}
          showMailingBox={showMailingBox}
        />
      </ThemeProvider>
    );
  }
}

AppFooter.propTypes = {
  items: PropTypes.object
};

let appFooter;

/**
 * Re-render the Footer
 *
 * @param {Object} state to override in component
 * @returns {undefined} undefined
 */
function rerender(state) {
  appFooter.setState(state);
}

/**
 * Mount the Footer
 * NOTE: This is meant to be executed only once when application starts
 *
 * @returns {undefined} undefined
 */
function mount() {
  appFooter = ReactDOM.hydrate(
    <AppFooter />,
    document.querySelector('.footer-container')
  );

  // hacky solution to get around the fact that classNames inside Footer don't update
  const emailInput = document.querySelector('[class*="StyledEmailColumn"]');
  const showEmail = document.querySelector('.show-email');
  if (emailInput === null) {
    showEmail.classList.remove('show-email');
  }
}

export default { mount, rerender };
