import React from 'react';
import ReactDOM from 'react-dom';

import Header from './header';
import Footer from './footer';
import Privacy from './privacy';

window.React = React;
window.ReactDOM = ReactDOM;

window.SC = window.SC || {};
// Use globally defined function
window.SC.react = Object.assign({}, window.SC.react, {
  rerenderHeader: Header.rerender,
  rerenderFooter: Footer.rerender
});

Header.mount();
Footer.mount();

if (window.location.pathname.includes('/profile/privacy/')) {
  Privacy.mount();
}
