/**
 * Find the country matching the region ID
 *
 * @param {Object} regionsByCountry regions grouped by countries
 * @param {number} regionId region ID
 * @returns {string} country
 */
function findCountry(regionsByCountry, regionId) {
  const currentCountry = Object.keys(regionsByCountry).filter(country => {
    const ids = regionsByCountry[country].map(x => x.id);
    return ids.indexOf(regionId) !== -1;
  });
  return currentCountry && currentCountry[0];
}

/**
 * Get the region with the given ID from `regionsByCountry`
 *
 * @param {Object} regionsByCountry regions grouped by countries
 * @param {number} regionId region ID
 * @returns {Object} matched region
 */
function getMatchingRegion(regionsByCountry, regionId) {
  const countries = Object.keys(regionsByCountry);
  for (let i = 0; i < countries.length; i++) {
    const country = countries[i];
    const regions = regionsByCountry[country];
    for (let j = 0; j < regions.length; j++) {
      const region = regions[j];
      if (region.id === regionId) {
        return region;
      }
    }
  }

  console.warn(`Expected to find region with id=${regionId}`);
  return null;
}

/**
 * Map studios and regions for client
 *
 * @param {Object} studiosByCountryWithRegions regions with studios grouped by country
 * @returns {Object} mapped regions
 */
function mapStudiosAndRegions(studiosByCountryWithRegions) {
  return Object.keys(studiosByCountryWithRegions).reduce((result, country) => {
    const countryRegions = studiosByCountryWithRegions[country];
    const regions = Object.keys(countryRegions).reduce((acc, regionId) => {
      const region = countryRegions[regionId];
      const { studios } = region;

      acc.push({
        id: region.id,
        title: region.title,
        sortOrder: region.sort_order,
        studios
      });
      return acc;
    }, []);

    result[country] = regions;
    return result;
  }, {});
}

export {
  findCountry,
  getMatchingRegion,
  mapStudiosAndRegions
};
