import { theme } from 'soulcycle-ui-kit';

// Use Legacy SCS breakpoints, instead of defaults to address issues
// with absolute positioning, especially within the header.
const themeWithLegacyBreakpoints = {
  ...theme,
  breakpoints: {
    mobile: 0,
    tablet: 768,
    desktop: 1024,
    desktopLarge: 1200,
    desktopWide: 1440
  }
};

export default themeWithLegacyBreakpoints;
