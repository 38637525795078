const headerDefault = Object.freeze({
  navItems: Object.freeze([
    {
      id: 'nav-experience',
      name: 'The Experience',
      url: '/new-to-soul/'
    },
    {
      id: 'nav-studio',
      name: 'Studios',
      url: '/studios/',
      subNav: [
        {
          id: 'subnav-studios',
          name: 'Find your Studio',
          url: '/studios/'
        },
        {
          id: 'subnav-learn',
          name: 'Learn',
          url: '/about/'
        },
        {
          id: 'subnav-supersoul',
          name: 'SuperSoul',
          url: '/supersoul/'
        },
        {
          id: 'subnav-series',
          name: 'Buy Series',
          url: '/series/'
        }
      ]
    },
    {
      id: 'nav-instructors',
      name: 'Instructors',
      url: '/instructors/',
      matchingPattern: /instructors/gi
    },
    {
      id: 'nav-connected',
      name: 'Soul Connected',
      url: '/soulconnected/',
      subNav: [
        {
          id: 'nav-whats-new',
          name: 'What’s New',
          url: '/soulconnected/articles/'
        },
        {
          id: 'nav-refer-friend',
          name: 'Refer a Friend',
          url: '/soulconnected/refer-a-friend/'
        },
        {
          id: 'nav-sustainability',
          name: 'Sustainability',
          url: '/soulconnected/sustainability/'
        },
        {
          id: 'nav-your-safety',
          name: 'Your Safety',
          url: '/soulconnected/soulstandard/'
        }
      ]
    },
    {
      id: 'nav-at-home',
      name: 'At Home Bike',
      url: '/at-home/'
    },
    {
      id: 'in-studio-shop',
      name: 'Shop Collection',
      url: '/shop/'
    }
  ]),
  studios: [
    {
      id: 1,
      title: '19th Street'
    },
    {
      id: 2,
      title: 'Brooklyn Heights'
    },
    {
      id: 3,
      title: 'Bryant Park'
    },
    {
      id: 4,
      title: 'Chelsea'
    },
    {
      id: 5,
      title: 'East 54th Street'
    },
    {
      id: 6,
      title: 'East 63rd Street'
    },
    {
      id: 7,
      title: 'East 83rd Street'
    },
    {
      id: 8,
      title: '19th Street'
    },
    {
      id: 9,
      title: 'Brooklyn Heights'
    },
    {
      id: 10,
      title: 'Bryant Park'
    },
    {
      id: 11,
      title: 'Chelsea'
    },
    {
      id: 12,
      title: 'East 54th Street'
    },
    {
      id: 13,
      title: 'East 63rd Street'
    },
    {
      id: 14,
      title: 'East 83rd Street'
    }
  ],

  userBadgeList: Object.freeze([
    { text: 'Upcoming Classes', url: '/profile/upcoming/reserved_classes/' },
    { text: 'History', url: '/profile/history/classes/'},
    { text: 'My Soul', url: '/profile/my_soul/about_me/' },
    { text: 'SoulJourney', url: '/profile/souljourney/' },
    { text: 'Refer a Friend', url: '/profile/spreadthesoul/' }
  ])
});

const headerUK = Object.freeze({
  navItems: Object.freeze([
    {
      id: 'what-is-soul',
      name: 'SoulConnected',
      url: '/soulconnected/'
    },
    {
      id: 'in-studio',
      name: 'Our Studios',
      url: '/studios/',
      subNav: [
        {
          id: 'in-studio-studios',
          name: 'Find Your Studio',
          url: '/studios/',
          matchingPatterns: [/\/find-a-class\//, /\/studios\//]
        },
        {
          id: 'in-studio-instructors',
          name: 'Instructors',
          url: '/instructors/',
          matchingPatterns: [/\/instructors\//]
        },
        {
          id: 'in-studio-learn',
          name: 'Learn',
          url: '/about/'
        },
        {
          id: 'in-studio-supersoul',
          name: 'SuperSoul',
          url: '/supersoul/'
        },
        {
          id: 'in-studio-buy-series',
          name: 'Buy Series',
          url: '/series/',
          matchingPatterns: [/\/suggested-series\//]
        }
      ]
    },
    {
      id: 'in-studio-shop',
      name: 'Soul Shop',
      url: '/soulconnected/uk-shop/'
    }
  ]),

  studios: [
    {
      id: 1100,
      title: 'London Studio'
    }
  ],

  userBadgeList: Object.freeze([
    { text: 'Upcoming Classes', url: '/profile/upcoming/reserved_classes/' },
    { text: 'History', url: '/profile/history/classes/' },
    { text: 'My Soul', url: '/profile/my_soul/about_me/' },
    { text: 'SoulJourney', url: '/profile/souljourney/' },
    { text: 'Refer a Friend', url: '/profile/spreadthesoul/' }
  ])
});

const userBadgeCampaign = { text: 'Warrior Week!', url: '/warrior-week' };

export { headerDefault, headerUK, userBadgeCampaign };
