import React from 'react';
import ReactDOM from 'react-dom';

import PrivacyForm from './privacy/PrivacyForm';


let privacyForm;

/**
 * Re-render the privacy form
 *
 * @param {Object} state to override in component
 * @returns {undefined} undefined
 */
const rerender = (state) => {
  privacyForm.setState(state);
};

/**
 * Mount the privacy form
 * NOTE: This is meant to be executed only once when application starts
 *
 * @param {Object} props to override in component
 * @returns {undefined} undefined
 */
const mount = () => {
  privacyForm = ReactDOM.hydrate(
    <PrivacyForm />,
    document.querySelector('.privacy-form-container')
  );
};

export default { mount, rerender };
