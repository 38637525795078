import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'soulcycle-ui-kit';


/**
 * Handle button logic
 *
 * @param {object} params props
 * @return {Button} button from soulcycle-ui-kit
 */
const PrivacyButton = ({action, handleButtonClick, btnDisabled, allButtonsDisabled, children}) => {
  const onClick = (e) => handleButtonClick(e, action);

  return (
    <Button id={`${action}-btn`} className="privacy-btn" onClick={onClick} disabled={btnDisabled || allButtonsDisabled} text={children} />
  );
};

PrivacyButton.propTypes = {
  action: PropTypes.string.isRequired,
  btnDisabled: PropTypes.bool.isRequired,
  allButtonsDisabled: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func,
  children: PropTypes.string
};

export default PrivacyButton;
